import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentBookingComponent } from '../../features/patient/modules/appointment-booking/appointment-booking.component';
import { AppointmentBookingModule } from '../../features/patient/modules/appointment-booking/appointment-booking.module';
import { CommonStepperModule } from '../../features/patient/modules/shared/common-stepper/common-stepper.module';
import { ThankYouComponent } from '../../features/patient/modules/appointment-booking/components/thank-you/thank-you.component';
import { TranslateModule,TranslateService } from '@ngx-translate/core';



@NgModule({
  declarations: [
    // AppointmentBookingComponent
  ],
  imports: [CommonModule,AppointmentBookingModule,TranslateModule,CommonStepperModule],
  exports: [
              // AppointmentBookingComponent,
              TranslateModule,
              CommonStepperModule,
              AppointmentBookingModule
            ]
})
export class sharedComponent { }
